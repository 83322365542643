@import url('assets/font/poppins/style.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply overflow-x-hidden;
    -webkit-tap-highlight-color: transparent;
  }
  body {
    @apply cursor-default poppins-regular !bg-general-50;
  }
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  a {
    @apply no-underline transition text-primary-700 lg:hover:text-primary-500;
  }
  #root {
    @apply flex flex-col w-full sm:min-h-screen;
  }
  .hidden-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .hidden-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

@layer components {
  .poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }

  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
  }
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
}

@layer utilities {
  .shadow-none {
    box-shadow: none;
  }
}

.mantine-Paper-root {
  @apply !rounded-xl md:!rounded-2xl;
}
.mantine-Checkbox-input {
  @apply !bg-primary-100 !border-primary-100 checked:!bg-primary-700 checked:!border-primary-700 [&[data-indeterminate]]:!bg-primary-400 disabled:!bg-general-100;
}
.mantine-Input-input:not(textarea) {
  @apply h-auto min-h-[50px];
}
.mantine-Switch-track {
  @apply !bg-primary-100 !border-primary-100 pl-2 pr-4;
}
.mantine-Switch-input:checked + .mantine-Switch-track {
  @apply !bg-primary-700 !border-primary-700 pl-4 pr-2;
}
.mantine-Switch-thumb {
  @apply !left-1;
}
.mantine-Switch-input:checked + .mantine-Switch-track  .mantine-Switch-thumb {
  @apply !left-[initial] !right-1;
}
.mantine-Switch-trackLabel {
  @apply text-black;
}
.mantine-Switch-input:checked + .mantine-Switch-track .mantine-Switch-trackLabel {
  @apply text-white;
}
.mantine-Switch-root[data-size="xl"] .mantine-Switch-track {
  @apply h-10;
}
.mantine-PillsInputField-field:focus {
  box-shadow: none;
}

.mantine-datatable-table {
  @apply !text-base;
}
.mantine-datatable-table tbody > tr:last-child {
  @apply border-0;
}
.mantine-Table-th,
.mantine-Table-td {
  @apply !p-4;
}
.mantine-datatable-header {
  @apply !z-[3];
}
.mantine-ScrollArea-viewport {
  @apply max-h-[80vh];
}
.mantine-datatable-header-selector-cell,
.mantine-datatable-row-selector-cell {
  @apply bg-white z-[2];
}
.mantine-datatable-row:not([data-selected="true"]):hover,
.mantine-datatable-row:not([data-selected="true"]):hover .mantine-datatable-row-selector-cell {
  @apply bg-primary-50;
}
.mantine-datatable-header-cell-sortable-group > *:nth-child(1) {
  @apply order-2;
}
.mantine-datatable-header-cell-sortable-group > .mantine-datatable-header-cell-filter-action-icon {
  @apply order-1 border-0 -mr-3 [&_svg]:w-5 [&_svg]:h-5 [&_svg]:fill-general-300 [&_svg]:stroke-transparent;
}
.mantine-datatable-row-expansion-cell {
  @apply !p-0;
}
.mantine-datatable-pagination:has(:nth-child(2)) > *:first-child {
  @apply hidden;
}
.mantine-datatable-pagination-text {
  @apply bg-white z-[1] text-center !text-base !text-general-500 !py-2;
}
.mantine-Center-root {
  margin-top: 35px;
}
.mantine-datatable-pagination {
  @apply justify-center;
}
.mantine-Pagination-control {
  @apply !bg-primary-50 hover:!bg-primary-100 !text-primary-700 !border-0 transition font-medium;
}
.mantine-Pagination-control[data-active="true"] {
  @apply !bg-primary-800 !text-white;
}
.mantine-Pagination-control[data-active="true"],
.mantine-Pagination-control[data-disabled="true"] {
  @apply pointer-events-none;
}

.mantine-Table-th [data-active="true"] > .mantine-ActionIcon-icon {
  @apply text-primary-500;
}

.highcharts-container * {
  font-family: poppins;
}

.Toastify__toast {
  font-family: poppins !important;
}